import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div id=\"nav-links\" class=\"sidebar col-medium-2\" data-test-nav-links>\n  {{#each @links as |link|}}\n    {{#if link.permitted}}\n      {{#if link.external}}\n        <a class=\"nav-link\" href={{compute-url link.route}} data-test-nav-link>\n          {{link.name}}\n        </a>\n      {{else if link.id}}\n        <LinkTo class=\"nav-link\" @route={{link.route}} @model={{link.id}} data-test-nav-link>\n          {{link.name}}\n        </LinkTo>\n      {{else if link.queryParams}}\n        <LinkTo class=\"nav-link {{if (compute this.computeIsActive link) \"active\"}}\" @route={{link.route}} @query={{this.computeQueryParams link}} data-test-nav-link>\n          {{link.name}}\n        </LinkTo>\n      {{else}}\n        <LinkTo class=\"nav-link\" @route={{link.route}} data-test-nav-link>\n          {{link.name}}\n        </LinkTo>\n      {{/if}}\n    {{/if}}\n  {{/each}}\n</div>\n", {"contents":"<div id=\"nav-links\" class=\"sidebar col-medium-2\" data-test-nav-links>\n  {{#each @links as |link|}}\n    {{#if link.permitted}}\n      {{#if link.external}}\n        <a class=\"nav-link\" href={{compute-url link.route}} data-test-nav-link>\n          {{link.name}}\n        </a>\n      {{else if link.id}}\n        <LinkTo class=\"nav-link\" @route={{link.route}} @model={{link.id}} data-test-nav-link>\n          {{link.name}}\n        </LinkTo>\n      {{else if link.queryParams}}\n        <LinkTo class=\"nav-link {{if (compute this.computeIsActive link) \"active\"}}\" @route={{link.route}} @query={{this.computeQueryParams link}} data-test-nav-link>\n          {{link.name}}\n        </LinkTo>\n      {{else}}\n        <LinkTo class=\"nav-link\" @route={{link.route}} data-test-nav-link>\n          {{link.name}}\n        </LinkTo>\n      {{/if}}\n    {{/if}}\n  {{/each}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/nav-links/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/nav-links/index.hbs"}});
import Component from '@glimmer/component';
import { type Registry as Services, service } from '@ember/service';
import { action } from '@ember/object';
import isEmpty from 'lodash-es/isEmpty';

interface ILinkQueryParams {
  key: string;
  value: string;
}

export interface ILink {
  id?: string;
  name: string;
  route: string;
  activeOnRoutes?: string[];
  permitted: boolean;
  external?: boolean;
  queryParams?: ILinkQueryParams[];
  default?: boolean;
}

interface IArgs {
  links: ILink[];
}

interface NavLinksSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type IQueryParams = Record<string, string>;

export default class UiNavLinksComponent extends Component<NavLinksSignature> {
  @service router!: Services['router'];

  @action
  computeQueryParams(link: ILink): IQueryParams {
    const queryParams = link.queryParams as ILinkQueryParams[];

    return queryParams.reduce(
      (acc, query) => ({ ...acc, [query.key]: query.value }),
      {},
    );
  }

  @action
  computeIsActive(link: ILink): boolean {
    const { currentRoute } = this.router;

    if (!currentRoute) {
      return false;
    }

    const { name, queryParams } = currentRoute;

    const isSameRoute = link.activeOnRoutes
      ? link.activeOnRoutes.some((route) => name.startsWith(route))
      : name.startsWith(link.route);

    const linkQueryParams = link.queryParams as ILinkQueryParams[];

    const isSameQuery = linkQueryParams.every(
      (query) => queryParams[query.key] === query.value,
    );

    const presentQueries = linkQueryParams.reduce((acc, obj) => {
      const value = queryParams[obj.key];

      if (value) {
        return {
          ...acc,
          [obj.key]: value,
        };
      }

      return acc;
    }, {});

    if (isEmpty(presentQueries) && link.default) {
      return isSameRoute;
    }

    return isSameRoute && isSameQuery;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::NavLinks': typeof UiNavLinksComponent;
  }
}
