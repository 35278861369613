import { type Registry as Services, service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';
import { isAlreadyConnectedError } from 'uplisting-frontend/utils';

export default class ConnectCallbackAirbnbController extends BaseController {
  @service analytics!: Services['analytics'];

  queryParams = ['code'];
  code;

  public async handleCallbackResponse(): Promise<void> {
    const { code } = this;

    if (!code) {
      return;
    }

    const connection = this.store.createRecord('airbnb-official-connection', {
      code,
      status: 'connecting',
      accessLevel: 'read_only',
    });

    try {
      await connection.save();

      connection.selectedListingIds = connection.allListings.map(
        (listing) => listing.id,
      );

      await connection.save();

      this.analytics.trackEvent(AnalyticsEvents.airbnbConnectionAdded);

      await this.openConnectPage({ refresh: true });
    } catch (e) {
      await this.reportError(e as Error, connection);
    }
  }

  private async reportError(
    error: Error,
    connection: AirbnbOfficialConnectionModel,
  ): Promise<void> {
    const queryParams = { problem: true };

    if (isAlreadyConnectedError(error)) {
      Object.assign(queryParams, { existing: true });

      connection.unloadRecord();
    }

    await this.openConnectPage(queryParams);
  }

  private async openConnectPage(queryParams): Promise<void> {
    await this.router.transitionTo('connect.airbnb', {
      queryParams,
    });
  }
}
