import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import DS from 'ember-data';
import ReviewStatisticModel from 'uplisting-frontend/models/review-statistic';
import InsightsAirbnbController from 'uplisting-frontend/pods/insights/airbnb/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

interface IInsightsAirbnbRouteModel {
  statistics: DS.PromiseArray<ReviewStatisticModel>;
  compareToStatistics: DS.PromiseArray<ReviewStatisticModel> | [];
}

interface IParams {
  start: string;
  end: string;
  propertyIds: string[];

  compareToStart?: string;
  compareToEnd?: string;
  compareToPropertyIds?: string[];
}

export default class InsightsAirbnbRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/review-statistic')
  reviewStatisticRepository!: Services['repositories/review-statistic'];

  permission = 'insights.airbnb';

  queryParams = {
    start: {
      refreshModel: true,
    },
    end: {
      refreshModel: true,
    },
    propertyIds: {
      refreshModel: true,
    },

    compareToStart: {
      refreshModel: true,
    },
    compareToEnd: {
      refreshModel: true,
    },
    compareToPropertyIds: {
      refreshModel: true,
    },
  };

  async model(params: IParams) {
    const { reviewStatisticRepository } = this;

    const statistics = reviewStatisticRepository.query({
      start_date: params.start,
      end_date: params.end,
      property_ids: params.propertyIds,
    });

    const compareToStatistics = this.shouldFetchCompareToData(params)
      ? reviewStatisticRepository.query({
          start_date: params.compareToStart,
          end_date: params.compareToEnd,
          property_ids: params.compareToPropertyIds,
        })
      : [];

    return hash({
      properties: this.propertyRepository.findAll(),
      statistics,
      compareToStatistics,
    });
  }

  setupController(
    controller: InsightsAirbnbController,
    model: IInsightsAirbnbRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      ...transition.to?.queryParams,
      isLoading: false,
      record: model.statistics[0],
      compareToRecord: model.compareToStatistics[0],
    });
  }

  resetController(controller: InsightsAirbnbController) {
    controller.resetState();
  }

  private shouldFetchCompareToData(params: IParams): boolean {
    return !!(params.compareToStart && params.compareToEnd);
  }
}
